import { ButtonBase, Box } from '@mui/material';
import { SvgIcon } from '@mui/material';
import LogoutIcon from '@untitled-ui/icons-react/build/esm/LogOut01';
import { useAuth } from '@/features/auth/use-auth';
import { useNavigate } from 'react-router-dom';


export const LogoutButton = () => {

    const { signOut } = useAuth();
    const navigate = useNavigate();

    function logout() {
        signOut();
        navigate("/auth/login", { replace: true })
    }
    const icon = (
        <SvgIcon fontSize="small">
            <LogoutIcon />
        </SvgIcon>
    );
    return (
        <ButtonBase
            sx={{
                alignItems: 'center',
                borderRadius: 0.5,
                display: 'flex',
                textAlign: 'left',
                justifyContent: 'center',
                width: '80%',
                margin: '20px auto',
                py:1,
                '&:hover': {
                    backgroundColor: 'primary.light'
                }
            }}
            onClick={logout}
        >
            <Box
                component="span"
                sx={{
                    alignItems: 'center',
                    color: 'common.white',
                    display: 'inline-flex',
                    justifyContent: 'center',
                    pr: 1,
                }}
            >
                {icon}
            </Box>
            <Box
                component="span"
                sx={{
                    fontFamily: (theme) => theme.typography.fontFamily,
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: '24px',
                    whiteSpace: 'nowrap',
                    color: 'common.white'
                }}
            >
                Salir
            </Box>
        </ButtonBase>
    );
};