import { useRoutes } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import { Layout as AuthLayout } from '@/features/auth';
import { LoginForm } from '@/features/auth';

import { Layout as DashboardLayout } from '@/features/dashboard';

import { SampleList } from '@/features/samples';
import { SampleDetail } from '@/features/samples';
import { ResourcesList } from '@/features/support_resources';
import { BrigadeDetail } from '@/features/brigade/BrigadeDetail';
import { Map } from '@/features/map/Map';

import paths, { authPaths } from './paths';
import { SampleProvider } from '@/features/samples/context';


export const AppRoutes = () => {
  // const auth = useAuth();

  const routes = [
    {
      element: (
        <DashboardLayout>
          <SampleProvider>
            <Outlet />
          </SampleProvider>
        </DashboardLayout>
      ),
      children: [
        {
          index: true,
          path: paths.index,
          element: <SampleList />
        },
        {
          path: paths.sampleDetail,
          element: <SampleDetail />
        },
        {
          path: paths.resourcesList,
          element: <ResourcesList />
        },
        {
          path: paths.brigade,
          element: <BrigadeDetail />
        },
        {
          path: paths.map,
          element: <Map />
        },
      ]
    },
    {
      path: authPaths.index,
      element: (
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      ),
      children: [
        {
          path: authPaths.login,
          element: <LoginForm />
        },
      ]
    }
  ]

  const element = useRoutes(routes);

  return <>{element}</>;
};
