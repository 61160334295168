import { useTheme } from '@mui/material/styles';

export const Logo = (props) => {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;

  if (props.white) {
    return (
      <img src="/logo192.png" alt="Monitoreo" width="42" height="49" />
    );
  }

  return (
    <img src="/logo192.png" alt="Monitoreo" width="72" height="85" fill={fillColor} />
  );
};
