import React, { useCallback, useRef, useState } from 'react';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import RefreshIcon from '@untitled-ui/icons-react/build/esm/RefreshCw01';
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  OutlinedInput,
  Stack,
  SvgIcon,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import { useUpdateEffect } from '@/hooks/use-update-effect';
import { Filters } from '../types';

const tabOptions = [
  {
    label: 'Todas',
    value: 'all'
  },
  {
    label: 'Pendientes',
    value: 'pending'
  },
  {
    label: 'Registradas',
    value: 'submitted'
  },
];

const sortOptions = [
  {
    label: 'Más recientes',
    value: 'desc'
  },
  {
    label: 'Menos recientes',
    value: 'asc'
  }
];

type ListSearchProps = {
  onFiltersChange?: (filters: any) => void,
  onSortChange?: (sort: any) => void,
  sortBy?: string,
  sortDir?: "asc" | "desc",
};

export const ListSearch = (props: ListSearchProps) => {
  const {
    onFiltersChange, onSortChange,
    // sortBy = 'createdAt',
    sortDir = 'asc'
  } = props;
  const queryRef = useRef<HTMLInputElement>();
  const [currentTab, setCurrentTab] = useState('all');
  const [filters, setFilters] = useState<Filters>({
    query: undefined,
    status: undefined
  });

  const handleFiltersUpdate = useCallback(() => {
    onFiltersChange?.(filters);
  }, [filters, onFiltersChange]);

  useUpdateEffect(() => {
    handleFiltersUpdate();
  }, [filters, handleFiltersUpdate]);

  const handleTabsChange = useCallback((event: React.ChangeEvent<{}>, tab: string) => {
    setCurrentTab(tab);
    const status = tab === 'all' ? undefined : tab;

    setFilters((prevState: Filters) => ({
      ...prevState,
      status
    }));
  }, []);

  const handleQueryChange = useCallback((event: React.KeyboardEvent<{}>) => {
    event.preventDefault();
    const query = queryRef.current?.value || '';
    setFilters((prevState: Filters) => ({
      ...prevState,
      query
    }));
  }, []);

  const handleSortChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const sortDir = event.target.value;
    onSortChange?.(sortDir);
  }, [onSortChange]);

  return (
    <div>
      <Tabs
        indicatorColor="primary"
        onChange={handleTabsChange}
        scrollButtons="auto"
        sx={{ px: 3 }}
        textColor="primary"
        value={currentTab}
        variant="scrollable"
      >
        {tabOptions.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider />
      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        gap={3}
        sx={{ p: 3 }}
      >
        <Button
          variant='outlined'
          onClick={() => window.location.reload()}
          endIcon={
            <SvgIcon>
              <RefreshIcon />
            </SvgIcon>
          }
          sx={{lineHeight: "2.5"}}
        >
          Actualizar datos
        </Button>
        <Box
          component="form"
          // onSubmit={handleQueryChange}
          sx={{ flexGrow: 1 }}
        >
          <OutlinedInput
            defaultValue=""
            fullWidth
            onKeyUp={handleQueryChange}
            inputProps={{ ref: queryRef }}
            name="sampleAttributes"
            placeholder="Busca por código o zonas"
            startAdornment={(
              <InputAdornment position="start">
                <SvgIcon>
                  <SearchMdIcon />
                </SvgIcon>
              </InputAdornment>
            )}
          />
        </Box>
        <TextField
          label="Ordenar por"
          name="sort"
          onChange={handleSortChange}
          select
          SelectProps={{ native: true }}
          value={sortDir}
        >
          {sortOptions.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
      </Stack>
    </div>
  );
};
