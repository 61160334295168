import { Chip } from '@mui/material';
import * as chipsConfig from './config';

interface ReferenceChipProps {
    size?: 'small' | 'medium' | 'large',
    bold?: boolean
}

export const ReferenceChip = (props: ReferenceChipProps) => {
    const { size = "medium", bold=false } = props;
    return (<Chip
        label="Referencia"
        color="info"
        variant='outlined'
        sx={{
            px: chipsConfig.paddingX[size],
            fontSize: chipsConfig.fontSize[size],
            width: chipsConfig.width[size],
            ...(bold && {fontWeight: 'bold'})
        }}
    />);

}