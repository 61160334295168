
// import { format } from 'date-fns';
import {
  Box,
  Stack,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography
} from '@mui/material';
import UserIcon from './UserIcon';
import { Brigadist } from './types';

type ItemListProps = {
  item: Brigadist;
}

export const ItemListRow = (props: ItemListProps) => {
  const { item } = props;

  // const createdAt = item.createdAt && format(item.createdAt, 'MMM dd, yyyy');

  return (
    <>
      <TableRow
        key={item.id}
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 1.5,
          boxShadow: 0,
          transition: (theme) => theme.transitions.create(['background-color', 'box-shadow'], {
            easing: theme.transitions.easing.easeInOut,
            duration: 200
          }),
          [`& .${tableCellClasses.root}`]: {
            borderBottomWidth: 1,
            borderBottomColor: 'divider',
            borderBottomStyle: 'solid',
            borderTopWidth: 1,
            borderTopColor: 'divider',
            borderTopStyle: 'solid',
            '&:first-of-type': {
              borderTopLeftRadius: (theme) => theme.shape.borderRadius * 1.5,
              borderBottomLeftRadius: (theme) => theme.shape.borderRadius * 1.5,
              borderLeftWidth: 1,
              borderLeftColor: 'divider',
              borderLeftStyle: 'solid'
            },
            '&:last-of-type': {
              borderTopRightRadius: (theme) => theme.shape.borderRadius * 1.5,
              borderBottomRightRadius: (theme) => theme.shape.borderRadius * 1.5,
              borderRightWidth: 1,
              borderRightColor: 'divider',
              borderRightStyle: 'solid'
            }
          }
        }}
      >
        <TableCell>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            <Box sx={{px: {xs: 0, sm: 4}}}>
              <UserIcon />
            </Box>
            <div>
              <Typography
                noWrap
                variant="body1"
              >
                {item.username}
              </Typography>
              <Typography
                color="text.secondary"
                noWrap
                variant="body1"
              >
                {item.first_name} {item.last_name}
              </Typography>
              <Typography
                color="text.secondary"
                noWrap
                variant="body2"
              >
                {item.can_fill_forms ? "Puede llenar formularios" : "No puede llenar formularios"}
              </Typography>
            </div>
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
};
