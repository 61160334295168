import { createContext, useCallback, useEffect, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';

const STORAGE_KEY = 'accessToken';

var ActionType;
(function (ActionType) {
  ActionType['INITIALIZE'] = 'INITIALIZE';
  ActionType['SIGN_IN'] = 'SIGN_IN';
  ActionType['SIGN_OUT'] = 'SIGN_OUT';
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  userId: null,
  userData: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, userId, userData } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      userId,
      userData
    };
  },
  SIGN_IN: (state, action) => {
    const { userId, userData } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      userId,
      userData
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    userId: null,
    userData: null
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  signIn: (username, password) => Promise.resolve(),
  signOut: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const decodeJWT = token => {
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid token format');
    }
    // const header = JSON.parse(atob(parts[0]));
    const payload = JSON.parse(atob(parts[1]));
    const userId = payload.user_id;
    return userId;
  }

  const retrieveUserData = async (token) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/brigadists-api/user-info`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    if (!response.ok) {
      const response_json = await response.json()
      toast.error(response_json.detail);
      throw new Error('Invalid credentials');
    }
    const userData = await response.json();
    return userData;
  }

  const initialize = useCallback(async () => {
    try {
      const accessToken = window.sessionStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        const userId = decodeJWT(accessToken);
        const userData = await retrieveUserData(accessToken);

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            userId,
            userData
          }
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            userId: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          userId: null
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
    initialize();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);



  const signIn = useCallback(async (username, password) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/brigadists-api/token/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ username, password })
    })
    if (!response.ok) {
      const response_json = await response.json()
      toast.error(response_json.detail);
      throw new Error('Invalid credentials');
    }
    const { access, refresh } = await response.json();
    // Decode JWT token
    const userId = decodeJWT(access);
    sessionStorage.setItem(STORAGE_KEY, access);
    sessionStorage.setItem('refreshToken', refresh);
    const userData = await retrieveUserData(access);

    dispatch({
      type: ActionType.SIGN_IN,
      payload: {
        userId,
        userData
      }
    });
  }, [dispatch]);

  const signOut = useCallback(async () => {
    sessionStorage.removeItem(STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signIn,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuthContext = () => useContext(AuthContext);