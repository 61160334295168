import { useContext } from 'react';
import { SampleContext } from '@/features/samples/context';

import './mapStyle.css';

export const Map = () => {
    const { map } = useContext(SampleContext);

    return (
        <>  {
            map &&
            <iframe
                srcDoc={map.html}
                className='map'
                frameBorder={0}
                scrolling="no"
                title="Mapa"
            />
        }
        </>);
};