import { createContext, useEffect, useState, FC, ReactNode } from "react";
import { CampaignListingElement, SampleListingElement, SampleDataDetail, MapData } from './types';
import { Brigadist } from "../brigade/types";
import { SupportResource } from "../support_resources/types";
import { useAuth } from '@/features/auth/use-auth';

import { htmlError } from "./utils";

type stateTypes = {
    samples: SampleListingElement[];
    campaigns: CampaignListingElement[];
    supportResources: SupportResource[];
    samplesData: SampleDataDetail[];
    brigadists: Brigadist[];
    map: MapData | null,
}

const intialState: stateTypes = {
    samples: [],
    campaigns: [],
    supportResources: [],
    samplesData: [],
    brigadists: [],
    map: null,
};

export const SampleContext = createContext({
    ...intialState,
});
SampleContext.displayName = 'SampleContext';

type Props = {
    children: ReactNode;
};

export const SampleProvider: FC<Props> = ({ children }) => {

    const [samples, setSamples] = useState<SampleListingElement[]>([]);
    const [campaigns, setCampaigns] = useState<CampaignListingElement[]>([]);
    const [supportResources, setSupportResources] = useState<SupportResource[]>([]);
    const [samplesData, setSamplesData] = useState<SampleDataDetail[]>([]);
    const [brigadists, setBrigadists] = useState<Brigadist[]>([]);
    const [map, setMap] = useState<MapData | null>(null);

    const { signOut } = useAuth();

    useEffect(() => {
        const fetchData = async (route: string) => {
            const token = window.sessionStorage.getItem('accessToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/brigadists-api/${route}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 401) {
                signOut();
            }
            if (response.status >= 400) {
                throw new Error(`Error al cargar los datos de ${route}`);
            }
            return await response.json();
        };
        const fetchMap = async () => {
            const token = window.sessionStorage.getItem('accessToken');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/data-explorer/map/1`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.status === 401) {
                signOut();
            }
            if (response.status >= 400) {
                return {
                    id: 1,
                    name: 'Error',
                    html: htmlError('el mapa'),
                };
            }
            return await response.json();
        };
        const getSamplesData = async (sampleIds: number[]) => {
            const samplesDataResponses: SampleDataDetail[] = [];
            sampleIds.forEach(async (sampleId: number) => {
                let sampleDataResponse = {
                    id: sampleId,
                    html: htmlError(`los datos del monitoreo`),
                    plugins: [
                        {
                            id: 1,
                            name: 'Error',
                            html: htmlError(`los datos del monitoreo`),
                        }
                    ]
                };
                try {
                    sampleDataResponse = await fetchData(`samples/${sampleId}/data`);
                    if (sampleDataResponse.plugins.length === 0) {
                        sampleDataResponse.plugins = [
                            {
                                id: 1,
                                name: 'Error',
                                html: htmlError(`la evaluación del monitoreo`),
                            }
                        ];
                    }
                } catch (error) {
                    console.error(error);
                }
                samplesDataResponses.push(sampleDataResponse);
            });
            setSamplesData(samplesDataResponses);
        };
        const loadResources = async () => {
            const campaignsResponse = await fetchData('campaigns');
            setCampaigns(campaignsResponse);
            const samplesResponse = await fetchData('samples');
            setSamples(samplesResponse);
            try {
                const supportResourcesResponse = await fetchData('support-resources');
                setSupportResources(supportResourcesResponse);
            } catch (error) {
                console.error(error);
            }
            try {
                const brigadistsResponse = await fetchData('brigade-brigadists');
                setBrigadists(brigadistsResponse);
            } catch (error) {
                console.error(error);
            }
            const mapResponse = await fetchMap();
            setMap(mapResponse);
            /** If there are samples with status 'submitted' fetch the data */
            const submittedSamples = samplesResponse.filter(
                (sample: SampleListingElement) => sample.status === 'submitted'
            );
            if (submittedSamples.length > 0) {
                const submittedSamplesIds = submittedSamples.map(
                    (sample: SampleListingElement) => sample.id
                );
                getSamplesData(submittedSamplesIds);
            }
        };
        loadResources().catch(console.error);
    }, [signOut]);

    return (
        <SampleContext.Provider value={{ samples, campaigns, supportResources, samplesData, brigadists, map }}>
            {children}
        </SampleContext.Provider>
    );
};