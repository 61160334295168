
export const htmlError = (elementfailing: string) => `
<!DOCTYPE html>
<html>
    <head>
    <title>Error</title>
    <script
        src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.6/iframeResizer.contentWindow.js"
        integrity="sha512-hBWsS94l8+snzSPo759jDKZ3z3jn3WT4snJZTBaeMPbrCGzDrYdl2pN9EaXjh6IqEZC7wF10qcmp42TPRVgAYQ=="
        crossorigin="anonymous" referrerpolicy="no-referrer"
    >
    </script>
    </head>
    <style>
        div.error {
            font-family: 'Montserrat', sans-serif;
            margin: 1em; 
            padding: 1em;
            border-radius: 10px;
            border: 2px solid #f44336; 
            background-color: #ffebee;
        }
        h4, p {
            margin: 0.5em;
        }
        p {
            font-size: 0.9em;
        }
    </style>
    <body>
        <div class="error">
            <h4>Ha ocurrido un error al procesar ${elementfailing}.</h4>
            <p>Hemos sido notificados y lo arreglaremos lo antes posible.</p>
        </div>
    </body>
</html>
`;