import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';


interface LayoutProps {
  children?: React.ReactNode;
}

const StyledImg = styled('img')({});

export const Layout = (props: LayoutProps) => {
  const { children } = props;

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: 'background.default',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: {
          xs: 'column',
          md: 'row'
        }
      }}
    >
      <Box
        sx={{
          alignItems: 'flex-end',
          backgroundColor: 'primary.main',
          backgroundImage: 'url("/cover.jpeg")',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          color: 'common.white',
          display: 'flex',
          flex: {
            md: '0 0 auto',
            lg: '1 1 auto'
          },
          justifyContent: 'center',
          p: {
            xs: 2,
            md: 3
          }
        }}
      >
        <StyledImg
          sx={{maxWidth: {
            xs: 300,
            small: 400,
            md: 400,
            lg: 600,
          }}}
          src="https://monitor-app.mx/media/admin-interface/logo/Plecas_Software_monitoreo_3.png"
          alt="Instituciones participantes"
        />
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flex: {
            md: '1 1 auto',
            lg: '0 0 auto'
          },
          flexDirection: 'column',
          justifyContent: {
            md: 'center'
          },
          maxWidth: '100%',
          width: {
            md: 600
          }
        }}
      >
        <Box sx={{m: 8}}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
