import { Stack } from '@mui/material';
import { SampleDataPlugin } from "../types";
import IframeRenderer from '@/features/samples/components/IframeRenderer';

type SamplePluginDataProps = {
    pluginsData: SampleDataPlugin[],
}
export const SamplePluginData = (props: SamplePluginDataProps) => {
    const { pluginsData } = props;
    return (
        <Stack spacing={1}>
            {pluginsData.map(pluginData => (
                pluginData.html &&
                <IframeRenderer key={pluginData.id} src={pluginData.html} />
            ))}
        </Stack>
    )
}