import PropTypes from 'prop-types';
import { Drawer, Stack, Box } from '@mui/material';
import { usePathname } from '@/hooks/use-pathname';
import { SideNavHeader } from './side-nav-header';
import { SideNavSection } from './side-nav-section';
import { LogoutButton } from '../LogoutButton';

const SIDE_NAV_WIDTH = 280;

export const SideNav = (props) => {
  const { sections = [], open, onClose, variant } = props;
  const pathname = usePathname();

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRightStyle: 'solid',
          borderRightColor: 'primary.dark',
          borderRightWidth: 1,
          color: 'primary.contrastText',
          width: SIDE_NAV_WIDTH,
          background: (theme) => `linear-gradient(180deg, ${theme.palette.primary.lightest} 0%, ${theme.palette.primary.main} 100%)`,
        }
      }}
      variant={variant}
    >
      <Box sx={{
        backgroundImage: 'url("/sidenav-bg.png")',
        opacity: 0.15,
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      }} />

      <Stack sx={{ height: '100%' }}>
        <SideNavHeader />
        <Stack
          component="nav"
          spacing={2}
          sx={{
            flexGrow: 1,
            px: 1,
            py: 3
          }}
        >
          {sections.map((section, index) => (
            <SideNavSection
              section={section}
              pathname={pathname}
              key={index}
            />
          ))}
        </Stack>
        <LogoutButton />
      </Stack>
    </Drawer>
  );
};

SideNav.propTypes = {
  sections: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.string
};
