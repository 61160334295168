import { Grid, Box, Divider } from '@mui/material';
import { Sample } from './Sample';
import { useContext, useState, useCallback } from 'react';
import { ListSearch } from './components/ListSearch';
import { Filters } from './types';

import { SampleContext } from './context';


type OrdersSearchState = {
    filters: Filters,
    sortBy: string,
    sortDir: "asc" | "desc",
}


const useOrdersSearch = () => {
    const [state, setState] = useState<OrdersSearchState>({
        filters: {
            query: undefined,
            status: undefined
        },
        sortBy: 'from_date',
        sortDir: 'desc'
    });

    const handleFiltersChange = useCallback((filters: Filters) => {
        setState((prevState: OrdersSearchState) => ({
            ...prevState,
            filters
        }));
    }, []);

    const handleSortChange = useCallback((sortDir: "asc" | "desc") => {
        setState((prevState: OrdersSearchState) => ({
            ...prevState,
            sortDir
        }));
    }, []);

    return {
        handleFiltersChange,
        handleSortChange,
        state
    };
};

export const SampleList = () => {
    const { campaigns, samples } = useContext(SampleContext);
    const ordersSearch = useOrdersSearch();
    const samplesToShow = samples.filter(sample => {
        if (ordersSearch.state.filters.status && sample.status !== ordersSearch.state.filters.status) {
            return false;
        }
        if (ordersSearch.state.filters.query) {
            const strToSearch = `${sample.code} ${sample.zones} ${sample.campaign}`.toLowerCase();
            const query = ordersSearch.state.filters.query.toLowerCase();
            if (!strToSearch.includes(query)) {
                return false;
            }
        }
        return true;
    });
    // Orders samples by date
    samplesToShow.sort((a, b) => {
        if (ordersSearch.state.sortDir === 'asc') {
            return new Date(a.date_from).getTime() - new Date(b.date_from).getTime();
        }
        return new Date(b.date_from).getTime() - new Date(a.date_from).getTime();
    });
    // Order campaigns by date
    campaigns.sort((a, b) => {
        if (ordersSearch.state.sortDir === 'asc') {
            return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
        }
        return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
    });
    return (
        <>
            <Box>
                <ListSearch
                    onFiltersChange={ordersSearch.handleFiltersChange}
                    onSortChange={ordersSearch.handleSortChange}
                    sortDir={ordersSearch.state.sortDir}
                    sortBy={ordersSearch.state.sortBy}
                />
                {
                    campaigns.filter(
                            campaign => samplesToShow.map(sample => sample.campaign).includes(campaign.id)
                        ).map(campaign => (
                        <Box key={campaign.id}>
                            <Divider textAlign='left' sx={{
                                mx: 2,
                                fontSize: 16,
                                'span': {
                                    width: {
                                        xs: 800,
                                        md: 1200,
                                    },
                                    whiteSpace: {
                                        xs: 'pre-line',
                                    }
                                }
                            }}>
                                Campaña: {campaign.name} - Del {campaign.start_date} al {campaign.end_date}
                            </Divider>
                            <Grid container spacing={2} key={campaign.id} sx={{ mb: 2}}>
                                {samplesToShow.filter(sample => sample.campaign === campaign.id).map(sample => (
                                    <Grid item key={sample.code} xs={12} sm={6} md={6} >
                                        <Sample
                                            key={sample.code}
                                            id={sample.id}
                                            code={sample.code}
                                            zones={sample.zones}
                                            dateFrom={sample.date_from}
                                            dateTo={sample.date_to}
                                            isReference={sample.is_reference}
                                            status={sample.status}
                                            site={sample.site_name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    ))
                }
            </Box >
        </>);
};