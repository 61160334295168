import Calendar from '@untitled-ui/icons-react/build/esm/Calendar';

import { Stack, SvgIcon, Typography } from '@mui/material';


interface DatesRowProps {
    dateFrom: string,
    dateTo: string,
    size?: 'small' | 'medium' | 'large',
}

export const DatesRow = (props: DatesRowProps) => {
    const { size="medium", dateFrom, dateTo } = props;
    const fontSize : any = {
        small: 12,
        medium: 14,
        large: 16
    };
    const lineHeight : any = {
        small: 1,
        medium: 1.5,
        large: 2
    };
    return (
        <Stack direction="row" spacing={1} >
            <SvgIcon color="disabled" fontSize={size} >
                <Calendar />
            </SvgIcon>
            <Typography sx={{
                fontSize: fontSize[size],
                lineHeight: lineHeight[size]
            }}>
                {`Desde ${dateFrom} hasta ${dateTo}`}
            </Typography>
        </Stack>
    )
}