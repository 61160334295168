
// import { format } from 'date-fns';
import {
  Box,
  Stack,
  TableCell,
  tableCellClasses,
  TableRow,
  Typography
} from '@mui/material';
import { bytesToSize } from '@/utils/bytes-to-size';
import { FileIcon } from './file-icon';
import { SupportResource } from './types';

type ItemListProps = {
  item: SupportResource;
}

export const ItemListRow = (props: ItemListProps) => {
  const { item } = props;
  let size = bytesToSize(item.size);

  // const createdAt = item.createdAt && format(item.createdAt, 'MMM dd, yyyy');

  return (
    <>
      <TableRow
        key={item.id}
        sx={{
          backgroundColor: 'transparent',
          borderRadius: 1.5,
          boxShadow: 0,
          transition: (theme) => theme.transitions.create(['background-color', 'box-shadow'], {
            easing: theme.transitions.easing.easeInOut,
            duration: 200
          }),
          '&:hover': {
            backgroundColor: 'background.paper',
            boxShadow: 16
          },
          [`& .${tableCellClasses.root}`]: {
            borderBottomWidth: 1,
            borderBottomColor: 'divider',
            borderBottomStyle: 'solid',
            borderTopWidth: 1,
            borderTopColor: 'divider',
            borderTopStyle: 'solid',
            '&:first-of-type': {
              borderTopLeftRadius: (theme) => theme.shape.borderRadius * 1.5,
              borderBottomLeftRadius: (theme) => theme.shape.borderRadius * 1.5,
              borderLeftWidth: 1,
              borderLeftColor: 'divider',
              borderLeftStyle: 'solid'
            },
            '&:last-of-type': {
              borderTopRightRadius: (theme) => theme.shape.borderRadius * 1.5,
              borderBottomRightRadius: (theme) => theme.shape.borderRadius * 1.5,
              borderRightWidth: 1,
              borderRightColor: 'divider',
              borderRightStyle: 'solid'
            }
          }
        }}
      >
        <TableCell>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            onClick={() => window.open(item.url, '_blank')}
          >
            <Box sx={{ cursor: 'pointer' }} >
              <FileIcon
                extension={item.extension}
              />
            </Box>
            <div>
              <Typography
                noWrap
                sx={{ cursor: 'pointer' }}
                variant="subtitle2"
              >
                {item.name}
              </Typography>
              <Typography
                color="text.secondary"
                noWrap
                variant="body2"
              >
                {item.description}
              </Typography>
            </div>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography
            noWrap
            variant="subtitle2"
          >
            Tamaño
          </Typography>
          <Typography
            color="text.secondary"
            noWrap
            variant="body2"
          >
            {size}
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};
