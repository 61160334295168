import { Theme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SideNav } from './side-nav';
import { TopNav } from './top-nav';
import { useMobileNav } from './use-mobile-nav';
import { useSections } from '../sections';
import { withAuthGuard } from '@/features/auth/with-auth-guard';

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout = withAuthGuard((props : LayoutProps) => {
  const { children } = props;
  const sections = useSections();
  const lgUp = useMediaQuery((theme:Theme) => theme.breakpoints.up('lg'));
  const mobileNav = useMobileNav();

  return (
    <>
      {!lgUp && <TopNav onMobileNavOpen={mobileNav.handleOpen} />}
      <SideNav
        sections={sections}
        open={lgUp ? true : mobileNav.open}
        onClose={mobileNav.handleClose}
        variant={lgUp ? 'persistent' : 'temporary'}
      />
      <LayoutRoot>
        <LayoutContainer>
          {children}
        </LayoutContainer>
      </LayoutRoot>
    </>
  );
});
