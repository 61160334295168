import { Chip } from '@mui/material';
import * as chipsConfig from './config'

interface StatusChipProps {
    status: string,
    size?: 'small' | 'medium' | 'large',
    bold?: boolean
}

export const StatusChip = (props: StatusChipProps) => {
    const { size = "medium", status, bold=false } = props;
    const statusConfigs: any = {
        submitted: {
            label: 'Registrado',
            color: 'primary',
        },
        pending: {
            label: 'Pendiente',
            color: 'error',
        },
        loading: {
            label: 'Cargando',
            color: 'primary',
        }
    };
    const statusConfig = statusConfigs[status];
    return (<Chip
        label={statusConfig.label}
        color={statusConfig.color}
        variant='outlined'
        sx={{
            px: chipsConfig.paddingX[size],
            width: chipsConfig.width[size],
            fontSize: chipsConfig.fontSize[size],
            ...(bold && {fontWeight: 'bold'})
        }}
    />);

}