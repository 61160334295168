
import IframeResizer from 'iframe-resizer-react'


const IframeRenderer = (props: { src: string }) => {

    return (
        <IframeResizer
            srcDoc={props.src}
            style={{
                width: '1px',
                minWidth: '100%',
            }}
            frameBorder="0"
            scrolling={false}
            title="Sample Data"
            checkOrigin={false}
        />
    );
}

export default IframeRenderer;