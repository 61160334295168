import { Button, Stack, Typography } from '@mui/material';


interface FormProps {
    formUrl?: string
}

export const Form = (props: FormProps) => {
    const { formUrl } = props;
    return (
        <Stack spacing={2} sx={{ p: 2 }} alignItems={{ xs: "center", md: "flex-start" }}>
            {!formUrl && (
                <Typography variant='body1' sx={{ fontStyle: 'italic' }}>
                    No cuentas con los permisos para llenar el formulario.
                </Typography>
            )}
            {formUrl && (
                <Stack spacing={2} sx={{maxWidth: 500}} alignItems={"center"}>
                    <Typography
                        variant='body1'
                        sx={{
                            fontStyle: 'italic'
                        }}
                    >
                        El siguiente botón te permitirá cargar el formulario
                        Kobo en tu navegador. Recuerda cargarlo 
                        mientras tengas conexión a internet.
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{
                            fontStyle: 'italic'
                        }}
                    >
                        Una vez que haya cargado podrás visitar esa página aún sin conexión,
                        te sugerimos agregarla a tus "Favoritos" en tu navegador.
                    </Typography>
                    <Button
                        variant='contained'
                        href={formUrl}
                        sx={{ width: 200 }}
                        target='_blank'
                    >
                        Cargar formulario
                    </Button>
                </Stack>
            )}
        </Stack>
    )
}