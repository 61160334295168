import { Box, Table, TableBody, Typography } from '@mui/material';
import { ItemListRow } from './item-list-row';

import { useContext } from 'react';
import { SampleContext } from '../samples/context';
import { useAuth } from '../auth/use-auth';

export const BrigadeDetail = () => {
    const { brigadists } = useContext(SampleContext);
    const { userData }: { userData: any } = useAuth();
    const brigadeName = userData !== null ? userData["institution"]["name"] : "Brigada sin asignar";

    return (
        <>
            <Box sx={{ p: { xs: 2, sm: 6 } }}>
                <Typography variant='h4'>
                    Brigada: {brigadeName}
                </Typography>
                <Box>
                    <Table
                        sx={{
                            maxWidth: 400,
                            borderCollapse: 'separate',
                            borderSpacing: '0 8px',
                            pt: 4,
                        }}
                    >
                        <TableBody>
                            {brigadists.map((brigadist) => (
                                <ItemListRow
                                    key={brigadist.id}
                                    item={brigadist}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </Box>
        </>);
};