import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { SideNavItem } from './side-nav-item';


export const SideNavSection = (props) => {
  const { section, pathname } = props;
  const checkPath = !!(section.path && pathname);
  // const partialMatch = checkPath ? pathname.includes(item.path) : false;
  const exactMatch = checkPath ? pathname === section.path : false;

  return (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: 'none',
        m: 0,
        p: 0
      }}
    >
      <SideNavItem
        active={exactMatch}
        icon={section.icon}
        key={section.title}
        path={section.path}
        title={section.title}
      />
    </Stack>
  );
};

SideNavSection.propTypes = {
  section: PropTypes.object,
  pathname: PropTypes.string
};
