
const icons : { [key: string]: any } = {
  jpeg: '/assets/icons/icon-jpg.svg',
  jpg: '/assets/icons/icon-jpg.svg',
  mp4: '/assets/icons/icon-mp4.svg',
  pdf: '/assets/icons/icon-pdf.svg',
  png: '/assets/icons/icon-png.svg',
  svg: '/assets/icons/icon-svg.svg'
};

type FileIconProps = {
  extension?: string;
}

export const FileIcon = (props: FileIconProps) => {
  const { extension } = props;

  let icon;

  if (extension && Object.keys(icons).includes(extension)) {
    icon = icons[extension];
  } else {
    icon = '/assets/icons/icon-other.svg';
  }

  return <img src={icon} alt={extension} />;
};