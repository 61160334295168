import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useMounted } from '@/hooks/use-mounted';
import { Logo } from '@/components/logo';

import paths from '@/routes/paths';

import { useAuth } from '@/features/auth/use-auth';

const initialValues = {
  username: '',
  password: '',
  submit: null
};

const validationSchema = Yup.object({
  username: Yup
    .string()
    .max(255)
    .required('El nombre de usuario es requerido'),
  password: Yup
    .string()
    .max(255)
    .required('La contraseña es requerida')
});

export const LoginForm = () => {
  const { signIn } = useAuth();
  const isMounted = useMounted();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        await signIn(values.username, values.password);
        if (isMounted()) {
          window.location.href = paths.index;
        }
      } catch (err: any) {
        if (isMounted()) {
          helpers.setStatus({ success: false });
          // helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  return (
    <>
      <div>
        <Stack
          sx={{ mb: 4 }}
          spacing={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Logo />
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            MonitoR
          </Typography>
        </Stack>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
        >
          <Stack spacing={3}>
            <TextField
              autoFocus
              error={!!(formik.touched.username && formik.errors.username)}
              fullWidth
              helperText={formik.touched.username && formik.errors.username}
              label="Usuario"
              name="username"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.username}
            />
            <TextField
              error={!!(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label="Contraseña"
              name="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="password"
              value={formik.values.password}
            />
          </Stack>
          <Button
            fullWidth
            sx={{ mt: 3 }}
            size="large"
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting}
          >
            Ingresar
          </Button>
        </form>
      </div>
    </>
  );
};

