import { SampleDataDetail } from "../types";
import IframeRenderer from '@/features/samples/components/IframeRenderer';


type SampleDataProps = {
    sampleData: SampleDataDetail,
}

export const SampleData = (props: SampleDataProps) => {
    const { sampleData } = props;
    return <IframeRenderer src={sampleData.html} />
}