import { Stack, SvgIcon, Typography } from '@mui/material';
import BookmarkIcon from '@untitled-ui/icons-react/build/esm/Bookmark';
import { SizesMap } from './types';


interface CodeRowProps {
    code: string,
    size?: 'small' | 'medium' | 'large',
}

export const CodeRow = (props: CodeRowProps) => {
    const { size="medium" } = props;
    const fontSize : SizesMap = {
        small: 12,
        medium: 14,
        large: 16
    };
    const lineHeight : SizesMap = {
        small: 1,
        medium: 1.5,
        large: 2
    };
    return (
        <Stack direction="row" spacing={1} >
            <SvgIcon color="disabled" fontSize={size} >
                <BookmarkIcon />
            </SvgIcon>
            <Typography sx={{
                fontSize: fontSize[size],
                lineHeight: lineHeight[size]
            }}>
                {`Código: ${props.code}`}
            </Typography>
        </Stack>
    )
}