import { Button, Stack, Box, Typography } from '@mui/material';
import { Coordinates } from "./types";
import useGeolocation from "react-navigator-geolocation";
import { MapContainer, TileLayer, Marker } from 'react-leaflet'



interface IndicationsProps {
    coordinates?: Coordinates
}

const getMapsUrl = (from: any, to: Coordinates) => {
    const { latitude, longitude } = to;
    const { latitude: fromLatitude, longitude: fromLongitude } = from;
    return `https://www.google.com/maps/dir/${fromLatitude},${fromLongitude}/${latitude},${longitude}`;
}

export const Indications = (props: IndicationsProps) => {
    const { isEnabled: geolocationEnabled, coords: myCoordinates } = useGeolocation();
    const { coordinates } = props;
    if (!coordinates) return (
        <Stack spacing={2} sx={{ p: { xs: 0, sm: 2 } }}>
            <Typography variant='body1' sx={{ fontStyle: 'italic', maxWidth: 500 }}>
                Las coordendas del sitio de monitoreo no han sido
                definidas.
                Sin embargo aún puedes llenar el formulario.
            </Typography>
        </Stack>
    );
    return (
        <Stack spacing={2} sx={{ p: { xs: 0, sm: 2 } }} alignItems={{ xs: "center", md: "flex-start" }}>
            {geolocationEnabled && myCoordinates && (
                <Stack spacing={2} alignItems={"center"}>
                    <Typography variant='body1' sx={{ fontStyle: 'italic', maxWidth: 500, textAlign: "center" }}>
                        El siguiente botón te permitirá abrir Google Maps para que puedas
                        obtener indicaciones de cómo llegar al sitio de monitoreo:
                    </Typography>
                    <Button
                        variant='contained'
                        href={getMapsUrl(myCoordinates, coordinates)}
                        sx={{ width: 200 }}
                        target='_blank'
                    >
                        ¿Cómo llegar?
                    </Button>

                </Stack>
            )}
            {!myCoordinates && (
                <Stack spacing={2} alignItems={"center"}>
                    <Typography variant='body1' sx={{ fontStyle: 'italic', maxWidth: 500, textAlign: "center" }}>
                        No hemos podido obtener tu ubicación actual. Si quieres obtener
                        las instrucciones de navegación al sitio de monitoreo asegúrate de tener
                        activada la geolocalización en tu dispositivo y darle permisos
                        a tu navegador para acceder a ella.
                    </Typography>
                </Stack>
            )}
            <Box sx={{ height: { xs: 500, sm: 300 }, width: { xs: 300, sm: 500 } }}>
                <MapContainer center={[coordinates.latitude, coordinates.longitude]} zoom={12} scrollWheelZoom={false} style={{ height: '100%', width: '100%' }}>
                    <TileLayer
                        attribution={'&copy; <a href="https://www.openstreetmap.org/copyright">' +
                            'OpenStreetMap</a> contributors'}
                        url={"https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png"}
                    />
                    <Marker position={[coordinates.latitude, coordinates.longitude]} />
                </MapContainer>
            </Box>
        </Stack>
    )
}