import { Box, Table, TableBody, Typography } from '@mui/material';
import { Scrollbar } from '@/components/scrollbar';
import { ItemListRow } from './item-list-row';

import { useContext } from 'react';
import { SampleContext } from '../samples/context';

export const ResourcesList = () => {
    const { supportResources: resources } = useContext(SampleContext);

    return (
        <>
            <Box sx={{  p: 6 }}>
                <Typography variant='h4'>
                    Material de apoyo
                </Typography>
                <Scrollbar>
                    <Box>
                        <Table
                            sx={{
                                minWidth: 600,
                                borderCollapse: 'separate',
                                borderSpacing: '0 8px',
                                pt: 4,
                            }}
                        >
                            <TableBody>
                                {resources.map((resource) => (
                                    <ItemListRow
                                        key={resource.id}
                                        item={resource}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Scrollbar>
            </Box>
        </>);
};