import { useParams } from 'react-router';

import { useState, SyntheticEvent, useContext, useEffect } from 'react';
import { SampleListingElement } from "./types";
import { DetailHeader } from './components/DetailHeader';
import { SampleData } from './components/SampleData';
import { SamplePluginData } from './components/SamplePluginData';
import { Indications } from './Indications';
import { Form } from './Form';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useMediaQuery } from '@mui/material';
import { SampleContext } from './context';

export const SampleDetail = () => {
    const { samples, samplesData } = useContext(SampleContext);
    const { sampleId } = useParams();
    const [tabIndex, setTabIndex] = useState('indications');
    const mdUp = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        setTabIndex(newValue);
    };

    const getSampleById = (id: string | undefined) => {
        if (!id) return null;
        return samples.find(sample => sample.id === parseInt(id));
    }

    const getSampleDataById = (id: string) => {
        return samplesData.find(sampleData => sampleData.id === parseInt(id));
    }

    const sampleById = getSampleById(sampleId);
    const selectedSample: SampleListingElement = sampleById ? sampleById :
        {
            id: 0,
            site_name: 'Muestreo no encontrado',
            code: 'Muestreo no encontrado',
            zones: '-',
            date_from: '-',
            date_to: '-',
            status: 'loading',
            is_reference: false,
            campaign: 0
        };

    useEffect(() => {
        if (selectedSample.status === 'submitted') {
            setTabIndex('data');
        }
    }, [selectedSample.status]);

    const tabs = [];
    const indicationsTab = {
        label: 'Indicaciones',
        value: 'indications',
        content: <Indications coordinates={selectedSample.coordinates} />
    }

    if (selectedSample.status === 'pending') {
        tabs.push(indicationsTab);
        tabs.push({
            label: 'Formulario',
            value: 'form',
            content: <Form formUrl={selectedSample.form_url} />
        });
    } else if (selectedSample.status === 'submitted' && sampleId) {
        const sampleData = getSampleDataById(sampleId);
        const content = sampleData ? <SampleData sampleData={sampleData} /> : <Box>Cargando...</Box>;
        const pluginContent = sampleData ? <SamplePluginData pluginsData={sampleData.plugins} /> : <Box>Cargando...</Box>;
        tabs.push({
            label: 'Datos registrados',
            value: 'data',
            content: content
        });
        tabs.push({
            label: 'Evaluación',
            value: 'plugins',
            content: pluginContent
        });
    }

    return (
        <>
            {selectedSample && (
                <>
                    <DetailHeader
                        {...selectedSample}
                        size={mdUp ? "medium" : "small"}
                    />
                    <TabContext value={tabIndex}>
                        <Box sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                            px: 4,
                        }}>
                            <TabList onChange={handleTabChange}
                                aria-label="Muestreo">
                                {tabs.map(tab => (
                                    <Tab
                                        key={tab.value}
                                        label={tab.label}
                                        value={tab.value}
                                    />
                                ))}
                            </TabList>
                        </Box>
                        {tabs.map(tab => (
                            <TabPanel key={tab.value} value={tab.value}>
                                {tab.content}
                            </TabPanel>
                        ))}
                    </TabContext>
                </>
            )}
        </>
    );
};