import { Stack, Typography } from "@mui/material"
import { CodeRow } from "./CodeRow"
import { ZoneRow } from "./ZonesRow"
import { DatesRow } from "./DatesRow"
import { StatusChip } from "./chips/StatusChip"
import { ReferenceChip } from "./chips/ReferenceChip"


interface DetailHeaderProps {
    site_name: string,
    code: string,
    zones?: string,
    date_from?: string,
    date_to?: string,
    is_reference?: boolean,
    status: string,
    size: 'small' | 'medium' | 'large'
}


export const DetailHeader = (props: DetailHeaderProps) => {
    return (
        <Stack spacing={2} justifyContent="space-between" sx={{ py: 2, px:4, flexDirection: { xs: "column", sm: "row" } }}>
            <Stack spacing={2}>
                <Typography variant="h5" color="primary.main">
                    {props.site_name}
                </Typography>
                <CodeRow code={props.code} size={props.size} />
                {props.zones && <ZoneRow zones={props.zones} size={props.size} />}
                {props.date_from && props.date_to && (
                    <DatesRow
                        dateFrom={props.date_from}
                        dateTo={props.date_to}
                        size={props.size}
                    />
                )}
            </Stack>
            <Stack spacing={2} sx={{ px: 2 }} justifyContent="flex-end">
                <StatusChip status={props.status} size={props.size}/>
                {props.is_reference && <ReferenceChip size={props.size} />}
            </Stack>
        </Stack>
    )
}