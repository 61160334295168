import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { AuthConsumer, AuthProvider } from '@/features/auth/auth-context';

import { createTheme } from '@/theme';
import { SplashScreen } from '@/components/splash-screen';
import { Toaster } from '@/components/toaster';

const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert"
    >
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
    </div>
  );
};

const theme = createTheme();

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex items-center justify-center w-screen h-screen">
          Spinner goes here!
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuthProvider>
          <AuthConsumer>
            {(auth) => {
              const showSplashScreen = !auth.isInitialized;
              return (
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <HelmetProvider>
                    <Toaster />
                    {
                      showSplashScreen ?
                        <SplashScreen /> :
                        <Router>{children}</Router>
                    }
                  </HelmetProvider>
                </ThemeProvider>
              )
            }}
          </AuthConsumer>
        </AuthProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
