import { Card, CardContent, Stack, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';
import { CodeRow } from './components/CodeRow';
import { ZoneRow } from './components/ZonesRow';
import { DatesRow } from './components/DatesRow';
import { StatusChip } from './components/chips/StatusChip';
import { ReferenceChip } from './components/chips/ReferenceChip';


interface SampleProps {
  id: number,
  code: string,
  site: string,
  zones?: string,
  dateFrom: string,
  dateTo: string,
  isReference?: boolean,
  status: string,
}

export const Sample = (props: SampleProps) => {
  const { id, code, zones, dateFrom, dateTo, isReference, status, site } = props;
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Card onClick={() => navigate(`/sample/${id}`)} sx={{ mx: 1 }}>
      <CardContent>
        <Stack spacing={1}>
          <Typography
            color={theme.palette.primary.main}
            variant="h6"
          >
            {site}
          </Typography>
          <CodeRow code={code} />
          {zones ? <ZoneRow zones={zones} /> : <ZoneRow zones="Por definir" />}
          {dateFrom && dateTo && <DatesRow dateFrom={dateFrom} dateTo={dateTo} />}
          <Stack direction="row" spacing={1}>
            <StatusChip status={status} />
            {isReference &&
              <ReferenceChip />
            }
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

