import { SizesMap } from "../types";

export const fontSize: SizesMap = {
    small: 10,
    medium: 13,
    large: 15
};
export const paddingX: SizesMap = {
    small: 1,
    medium: 3,
    large: 6
};
export const width: SizesMap = {
    small: 100,
    medium: 150,
    large: 200
};