import Droplets03 from '@untitled-ui/icons-react/build/esm/Droplets03';

import { Stack, SvgIcon, Typography } from '@mui/material';
import { usePopover } from '@/hooks/use-popover';
import { Selector } from './selector';

import { Logo } from '@/components/logo';

export interface MonitoringType {
  id: number,
  label: string,
  icon: any
}

const monitoringTypes : MonitoringType[] = [
  // {id: 0, label: "Agua", icon: <Droplets03 />}
];

export const SideNavHeader = () => {
  const popover = usePopover();

  return (
    <Stack
      alignItems="center"
      direction="column"
      spacing={2}
      sx={{ py: 2, px: 1, backgroundColor: 'primary.dark', position: 'relative' }}
    >
      <Stack direction={"row"} alignItems="center" sx={{px:4}}>
        <Logo white />
        <Typography
          color="primary.contrastText"
          align="center"
          sx={{
            fontSize: 24,
            fontWeight: 600,
            px: 2
          }}
        >
          MonitoR
        </Typography>
      </Stack>
      <Stack
        direction="row"
        // onClick={popover.handleOpen}
        ref={popover.anchorRef}
        spacing={1}
        justifyContent="center"
        sx={{
          backgroundColor: 'primary.lightest',
          width: '80%',
          borderRadius: 0.5,
          padding: 1,
        }}
      >
        <SvgIcon>
          <Droplets03 />
        </SvgIcon>
        <Typography
          color="primary.contrastText"
          variant="h6"
        >
          Agua
        </Typography>
        {/* <SvgIcon>
          <ChevronDownIcon />
        </SvgIcon> */}
      </Stack>
      <Selector
        anchorEl={popover.anchorRef.current}
        onChange={popover.handleClose}
        onClose={popover.handleClose}
        open={popover.open}
        options={monitoringTypes}
      />
    </Stack>
  );
};
