export const authPaths = {
    index: 'auth',
    login: 'login',
}

const paths = {
    index: '/',
    login: `${authPaths.index}/${authPaths.login}`,
    resourcesList: 'resources-list',
    sampleDetail: 'sample/:sampleId',
    brigade: 'brigade',
    map: 'map',
}

export default paths;