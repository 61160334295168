
import MarkerIcon from '@untitled-ui/icons-react/build/esm/MarkerPin04';
import FileIcon from '@untitled-ui/icons-react/build/esm/FileAttachment04';
import UsersIcon from '@untitled-ui/icons-react/build/esm/Users02';
import MapIcon from '@untitled-ui/icons-react/build/esm/Map01';

import { SvgIcon } from '@mui/material';
import paths from '@/routes/paths';


export const useSections = () => {

  return [
    {
      title: "Muestreos",
      path: paths.index,
      icon: (
        <SvgIcon fontSize="medium">
          <MarkerIcon />
        </SvgIcon>
      )
    },
    {
      title: "Material de apoyo",
      path: paths.resourcesList,
      icon: (
        <SvgIcon fontSize="medium">
          <FileIcon />
        </SvgIcon>
      )
    },
    {
      title: "Mi brigada",
      path: paths.brigade,
      icon: (
        <SvgIcon fontSize="medium">
          <UsersIcon />
        </SvgIcon>
      )
    },
    {
      title: "Mapa",
      path: paths.map,
      icon: (
        <SvgIcon fontSize="medium">
          <MapIcon />
        </SvgIcon>
      )
    },
  ]
};
