import { MenuItem, Popover } from '@mui/material';
import { MonitoringType } from './index';

interface SelectorProps {
  anchorEl: HTMLElement | null;
  onChange?: (tenant: string) => void;
  onClose: () => void;
  open?: boolean;
  options: MonitoringType[];
}

export const Selector = (props: SelectorProps) => {
  const { anchorEl, onChange, onClose, open = false, options, ...other } = props;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom'
      }}
      disableScrollLock
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top'
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 180 } }}
      {...other}>
      {options.map((option) => (
        <MenuItem
          key={option.id}
          onClick={() => onChange?.(option.label)}
          sx={{
            backgroundColor: 'primary.lightest',
          }}
        >
          {option.label}
        </MenuItem>
      ))}
    </Popover>
  );
};
